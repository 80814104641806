@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), local('Pretendard'), url('@/fonts/Pretendard-Black.woff') format('woff'),
    url('@/fonts/Pretendard-Black.woff2') format('woff2'), url('@/fonts/Pretendard-Black.otf') format('opentype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), local('Pretendard'), url('@/fonts/Pretendard-Bold.woff') format('woff'),
    url('@/fonts/Pretendard-Bold.woff2') format('woff2'), url('@/fonts/Pretendard-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 800;
}

.icon-arrow {
  opacity: 0.4;
}

@media (hover: hover) {
  .icon-arrow:hover {
    opacity: 1;
  }

  .icon-base:hover * {
    fill: #ff8100;
    stroke: #ff8100;
  }
}

.arrow .__react_component_tooltip {
  border-radius: 8px;
  padding: 8px 12px;
}

.post-wrap .__react_component_tooltip {
  border-radius: 0;
  padding: 0;
}

.desktop-only.__react_component_tooltip {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-only.__react_component_tooltip {
    display: inline-block;
  }
}
@media (max-width: 768px) {
  .icon-arrow {
    opacity: 1;
  }
}

.keep-all {
  word-break: keep-all;
}

#player > div {
  width: 100% !important;
}

#player iframe {
  width: 100% !important;
  height: 100% !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.favicon_rotate {
  animation: spinner 60s steps(60, end) infinite;
}

@keyframes bubbleColor {
  0% {
    background: #ff8100;
  }
  50% {
    background: #333333;
  }
  100% {
    background: #ff8100;
  }
}
.bubble-body {
  animation: bubbleColor 3s ease-in-out infinite;
}

.bubble {
  animation: bubbleColor 3s ease-in-out infinite;
  display: inline-block;
  width: 13px;
  height: 13px;
  right: -13px;
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  position: absolute;
  top: 13px;
}

.append-bg {
  position: relative;
}

.append-bg::after {
  content: '';
  position: absolute;
  height: 60vh;
  width: 100%;
  top: 100%;
  left: 0;
  right: 0;
  z-index: -1;
}

.audio-container .input-range {
  --seek-before-width: 0%;
  --buffered-width: 0%;
  --range-active-color: #ff8100;
  --range-gray-light: #eeeeee;
}

.audio-container input[type='range'] {
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 8px;
  outline: none;
}

.audio-container input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  background: var(--range-gray-light);
}

.audio-container input[type='range']::before {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  width: var(--seek-before-width);
  height: 14px;
  background-color: var(--range-active-color);
  cursor: pointer;
}

.audio-container input[type='range']::-webkit-slider-thumb {
  position: relative;
  -webkit-appearance: none;
  box-sizing: content-box;
  height: 14px;
  width: 14px;
  border: none;
  background-color: var(--range-active-color);
  cursor: pointer;
  border-radius: 0;
}

/* 인풋 클릭해서 드래그 시  */
.audio-container input[type='range']:active::-webkit-slider-thumb {
  background: var(--range-active-color);
}

.audio-container input[type='range']::-moz-range-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  /* background: black; */
}

.audio-container input[type='range']::-moz-range-progress {
  background-color: var(--range-active-color);
}

.audio-container input[type='range']::-moz-focus-outer {
  border: 0;
}

.audio-container input[type='range']::-moz-range-thumb {
  box-sizing: content-box;
  border: 1px solid var(--range-active-color);
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.audio-container input[type='range']:active::-moz-range-thumb {
  background: var(--range-active-color);
}

.audio-container input[type='range']::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  background: transparent;
  border: solid transparent;
  color: transparent;
}

.audio-container input[type='range']::-ms-fill-lower {
  background-color: var(--range-active-color);
}

.audio-container input[type='range']::-ms-fill-upper {
  background: linear-gradient(to right, rgba(0, 125, 181, 0.6) var(--buffered-width), rgba(0, 125, 181, 0.2) var(--buffered-width));
}

.audio-container input[type='range']::-ms-thumb {
  box-sizing: content-box;
  height: 14px;
  width: 14px;
  border-radius: 0;
  background-color: var(--range-active-color);
  cursor: pointer;
}

.audio-container input[type='range']:active::-ms-thumb {
  background: var(--range-active-color);
}
